@import '../../ButtonStyles.css';

.circles-page {
  padding: 2rem 0; /* Change 1rem to 0 */
}

.circles-page-content {
  max-width: 1200px; /* Add this class */
  margin: 0 auto;
  padding: 0 1rem;
}

.circles-page h2 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.filters {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.filter {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.filter label {
  margin-right: 0.5rem;
}

.filter select {
  padding: 0.25rem;
}

@media screen and (max-width: 767px) {
  .filters {
    flex-direction: column;
    align-items: left;
    margin-left: 1rem;
  }

  .filter {
    margin: 0.5rem 0;
  }
}

/* Modal*/

.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.ReactModal__Content {
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  width: 50%; /* Add this line */
  max-width: 80%;
  max-height: 80%;
  padding: 2rem;
  outline: none;
  overflow-y: auto;
}

@media screen and (max-width: 767px) {
  .ReactModal__Content {
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    width: 100%; /* Add this line */
    max-width: 80%;
    max-height: 90%;
    padding: 2rem;
    outline: none;
    overflow-y: auto;
  }
}

/* Add styles for labels and inputs in the modal */
.modal-label {
  display: block;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.modal-input {
  display: block;
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-bottom: 0rem;
}

.modal-input.topic {
  width: 90%;
}

.modal-input.description {
  width: 90%;
  height: 3rem;
}

.modal-checkbox-label {
  display: inline-block;
  margin-right: 1rem;
}

.modal-close-icon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.modal-checkbox-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .modal-checkbox-wrapper > div {
    flex-basis: 100%;
  }
}
@media (min-width: 768px) {
  .modal-checkbox-wrapper > div {
    flex-basis: 30%;
  }
}

.button-and-message {
  margin-top: 2rem;
  display: flex;
  align-items: center;
}

.submission-status.success {
  margin-left: 1rem;
}

.error-message {
  color: red;
  font-size: 0.8rem;
  margin: 0.5rem 0;
}





  