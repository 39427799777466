.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 100px); /* Adjust this value depending on the height of your header/footer */
    text-align: center;
  }
  
  .not-found h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .not-found p {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  
  .not-found a {
    font-size: 1rem;
    color: #0077cc; /* Use your website's link color */
    text-decoration: none;
  }
  
  .not-found a:hover {
    color: #005fa3; /* Use your website's link hover color */
    text-decoration: underline;
  }
  