.what-are-circles {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
  }
  
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .image-container {
    width: 40%;
  }
  
  .image-container img {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-left: 1rem;
    border-radius: 20px;
  }
  
  .text-container {
    width: 50%;
    margin-left: 4rem;
    margin-right: 2rem;
    text-align: left;
  }
  
  .text-container h2 {
    margin-top: 4rem;
    margin-bottom: 1rem;
    font-size: 2.2rem;
  }
  
  .text-container p {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.25rem;
    margin-right: 2rem;
    color: #525252;
  }
  
  @media screen and (max-width: 768px) {
    .container {
      flex-direction: column;
    }
  
    .image-container {
      width: 100%;
      margin-bottom: 2rem;
    }

    .image-container img {
        width: 80%;
        margin-left: 1rem;
        margin-right: 1rem;
      }
  
    .text-container {
      width: 90%;
      margin-left: 3rem;
      margin-right: 3rem;
    }

    .text-container h2 {
        margin-top: 0rem;
      }
  }
  
  