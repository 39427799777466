.individual-plc {
  border: 1px solid #ccc;
  border-radius: 30px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 220px;
  height: 330px;
  background-color: #f9f9f9;
  position: relative;
}

.enrollment-counter {
  position: absolute;
  top: 20px;
  right: 10px;
  display: flex;
  align-items: center;
}

.enrollment-counter img {
  height: 20px;
  margin-right: 5px;
}

.enrollment-counter span {
  font-size: 1rem;
}

.individual-plc h3 {
  padding-top: 1rem;
  font-size: 1.2rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  padding-bottom: 1rem;
}

.students-age-text {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.individual-plc p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  flex-grow: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  padding-bottom: 1.5rem;
}

.access-plc-btn {
  display: inline-block;
  text-decoration: none;
  padding: 12px 24px;
  font-size: 1.1rem;
  transition: background-color 0.3s;
  align-self: center;
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  border-radius: 50px; /* Change this to create rounded corners */
}

.access-plc-btn:hover {
  background-color: #f2f2f2;
}
