@import '../../ButtonStyles.css';

.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-top: 4rem;
  margin-bottom: 6rem;
}

.hero-content {
  flex-basis: 50%;
  text-align: left; /* Add this for left-aligned text */
}

.hero-content h1 {
  font-size: 3rem;
  margin-bottom: 2rem;
}

.hero-content p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  color: #525252;
}

.hero-image-container {
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  align-items: center; /* Add this for vertical alignment of the image */
}

.hero-image-container img {
  max-width: 100%;
  height: auto;
  max-height: calc(1.5 * (3rem + 2 * (1.25rem + 2rem) + 0.75rem * 2)); /* Add this to limit the height */
  object-fit: cover; /* Add this to maintain the aspect ratio */
}

@media (max-width: 768px) {
  .hero-image-container {
    display: none;
  }
}

@media (max-width: 768px) {
  .hero-section {
    margin-top: 0rem;
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .hero-content {
    flex-basis: 90%;
  }
}


