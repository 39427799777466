.btn {
    display: inline-block;
    background-color: #3F61DB;
    color: #fff;
    text-decoration: none;
    padding: 12px 24px;
    border-radius: 50px;
    font-size: 1.1rem;
    transition: background-color 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    border: none;
  }
  
  .btn:hover {
    background-color: #1F50FF; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); 
  }
