.add-circle-card {
    border: 1px dashed #7d7c7c;
    border-radius: 30px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 330px;
    background-color: #f9f9f9;
  }
  
  .add-circle-btn {
    display: inline-block;
    text-decoration: none;
    padding: 12px 24px;
    font-size: 1.1rem;
    transition: background-color 0.3s;
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 50px;
  }
  
  .add-circle-btn:hover {
    background-color: #f2f2f2;
  }
  