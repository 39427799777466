.contact-section {
  padding: 4rem 0;
  text-align: center;
}

.contact-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.contact-section h3 {
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.contact-section p {
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  text-align: justify;
}

.contact-image {
  width: 30%;
  max-width: 300px;
  margin: 1rem auto 2rem;
  display: block;
}

@media screen and (max-width: 767px) {
  .contact-section {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}




  