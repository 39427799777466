.steps-section {
  padding: 0;
}

.steps-section {
  padding: 2rem 1rem;
  background-color: #F2F2F2;
}

.steps-section h2 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.steps-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
  grid-gap: 1.5rem;
  justify-content: center;
  align-content: center;
  max-width: 1100px;
  margin: 0 auto;
}

.step-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: transparent;
}

.step-box img {
  width: 100%;
  max-width: 200px;
  height: auto;
  margin-bottom: 1rem;
}

.step-box h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.step-box p {
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: center;
}

