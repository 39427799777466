@import '../../ButtonStyles.css';

/* Overview Section */
.overview-section {
  padding: 4rem 0;
  text-align: center;
}

.overview-section h1 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.overview-section p {
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  text-align: justify;
}

/* Features Section */
.features-section {
  padding: 4rem 0;
  text-align: center;
}

.features-section h2 {
  font-size: 2.2rem;
  margin-bottom: 2rem;
}

.features-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.feature-item {
  flex: 1;
  max-width: 350px;
  text-align: center;
}

.feature-item img {
  width: 120px;
  height: 120px;
  margin-bottom: 1rem;
}

.feature-item h3 {
  font-size: 1.8rem;
}

.feature-item p {
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: justify;
  margin-left: 1rem;
  margin-right: 1rem;
}

/* Pricing and Plans Section */
.pricing-plans-section {
  padding: 4rem 0;
  text-align: center;
}

.pricing-plans-section h2 {
  font-size: 2.2rem;
  margin-bottom: 1.5rem;
}

.pricing-plans-section p {
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto 2rem auto;
  text-align: justify;
}

/* Contact or Onboarding Process Section */
.contact-onboarding-section {
  padding: 4rem 0;
  text-align: center;
}

.contact-onboarding-section h2 {
  font-size: 2.2rem;
  margin-bottom: 1.5rem;
}

.contact-onboarding-section p {
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto 2rem auto;
  text-align: justify;
}

.contact-onboarding-section ul {
  list-style: inside;
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto 2rem auto;
  text-align: left;
}

.contact-onboarding-section ul li {
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 767px) {
  .content-wrapper {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .features-grid {
    flex-direction: column;
    align-items: center;
  }
}