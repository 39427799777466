@import '../../ButtonStyles.css';

.circles-section {
  padding: 2rem 1rem;
  margin-top: 4px;
  margin-bottom: 4rem;
}

.circles-section h2 {
  font-size: 2.2rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.circles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-gap: 1.5rem;
  justify-content: center;
  align-content: center;
}

.homepage-circles-section .circles-grid {
  grid-gap: 1rem;
  max-width: 1100px;
  margin: 0 auto;
}

.all-circles-btn {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

@media (max-width: 767px) {
  .circles-grid {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 767px) {
  .circles-grid {
    grid-template-columns: 1fr;
  }

  .circles-grid .individual-plc {
    max-width: 220px; /* Set the max-width of the grid item */
    margin-left: auto; /* Auto left margin */
    margin-right: auto; /* Auto right margin */
  }
}

.add-circle-card-container {
  display: flex;
  justify-content: center;
}




  