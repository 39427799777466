@import '../../ButtonStyles.css';

.institutional-container {
  margin-bottom: 10rem;
}

.institutional-section,
.features-section {
  padding: 0rem 0;
  text-align: center;
  margin-bottom: -3rem;
}

.institutional-section h2,
.features-section h2 {
  font-size: 2.2rem;
  margin-bottom: 1.5rem;
}

@media (max-width: 767px) {
  .institutional-section h2,
  .features-section h2 {
    margin: 0 2rem 1rem 2rem;
  }
}

.institutional-section p {
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto 2rem auto;
  text-align: justify;
}

@media (max-width: 767px) {
  .institutional-section p {
    margin: 0 2rem 1rem 2rem;
  }
}

.features-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.feature {
  flex: 1 1 300px;
  max-width: 300px;
  margin: 1rem;
}

.feature img {
  width: 64px;
  height: 64px;
  margin-bottom: 1rem;
}

.feature h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.feature p {
  font-size: 1.25rem;
  line-height: 1.6;
  text-align: center;
}