.header {
  background-color: #3F61DB;
  padding: 1rem 0;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.container {
  max-width: 1400px; /* Increase the max-width */
}

.logo-sandwich-wrapper {
  display: flex;
  align-items: center;
}

.logo img {
  max-height: 3rem; /* Adjust the logo size */
  flex-shrink: 0; /* Prevent the logo from shrinking on smaller screens */
}

.main-nav {
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}

.main-nav ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  align-items: center;
}

.main-nav li {
  margin-left: 1.5rem;
}

.main-nav a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

.main-nav a:hover,
.main-nav a.active {
  color: #f0a500;
}

.sandwich-menu {
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
  display: none; /* Hide the sandwich menu by default */
  margin-right: 2rem;
}

@media screen and (max-width: 768px) {
  .sandwich-menu {
    display: block; /* Show the sandwich menu on small screens */
  }

  .main-nav {
    display: none; /* Hide the main nav on small screens */
  }

  .container {
    padding: 0 20px; /* Add padding to the container in mobile view */
    flex-direction: row;
    
  }

  .logo {
    width: calc(100% - 40px); /* Set the logo width to occupy the remaining space, minus the sandwich menu width */
  }

  .main-nav.open {
    display: flex;
    flex-direction: column;
    background-color: #3f61db;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    text-align: center;
  }

  .main-nav.open ul {
    flex-direction: column;
    padding: 1rem 0;
  }

  .main-nav.open li {
    margin: 1rem 0;
  }

  .sandwich-menu img {
    width: 24px;
    height: 24px;
  }
  
}


  
  