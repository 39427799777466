.plc-details {
  padding: 2rem 1rem;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  text-align: left;
}

.plc-image-desktop,
.plc-image-mobile {
  display: flex;
  align-items: left;
  justify-content: left;
  margin-top: 2rem;
}

.plc-image-desktop img,
.plc-image-mobile img {
  height: 25rem;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.plc-image-mobile {
  display: none;
}

@media (max-width: 767px) {
  .plc-image-desktop {
    display: none;
  }

  .plc-image-mobile {
    display: flex;
  }
}

.plc-details button {
  background-color:#3F61DB;
  color: #fff;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  top: 2rem;
  left: 0;
  transform: none;
  margin-bottom: 1rem;
}

.plc-details button:hover {
  background-color: #1F50FF; 
}

.plc-details h2 {
  font-size: 2rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.plc-details h3 {
  margin-top: 2rem;
}

.topic-tag {
  display: inline-block;
  background-color: inherit;
  padding: 0.25rem 1rem;
  border-radius: 5px;
  height: 1.5rem;
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: #000000;
  text-align: center;
  margin-bottom: 0.5rem;
}

.plc-counter {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.plc-counter img {
  margin-right: 0.5rem;
  height: 1.5rem;
}

.plc-counter-text {
  font-weight: normal;
  font-size: 1.1rem;
}

.enrolled-count {
  font-weight: bold;
}


.plc-students-age {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 2rem;
}

.plc-students-age img {
  margin-right: 0.5rem;
  height: 1.5rem;
}

.plc-students-age-text {
  font-weight: normal;
  font-size: 1.1rem;
}

.age-range {
  font-weight: bold;
}

.plc-details p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.plc-details ol {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}


.plc-details p:last-child {
  margin-bottom: 5rem;
}

.instruction-copy-button {
  padding: 0;
  margin-left: 5px;
}

.instruction-copy-icon {
  width: auto;
  display: inline;
}

.instruction-copy-icon:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.instruction-copy-icon span {
  margin-left: 10px;
  color: black;
}

.share-icon {
  margin-right: 10px;
  cursor: pointer;
  display: inline-block;
}

.copy-link {
  display: inline-flex;
  align-items: center;
  background-color: transparent !important;
  border: none;
  cursor: pointer;
}

.copy-link img {
  width: 80%;
  height: 80%;
}

.copy-link:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.copy-link span {
  margin-left: 10px;
  color: black;
}

/* Styles for mobile devices */
@media (max-width: 767px) {
  .plc-image-mobile {
    margin-top: 0rem;
  }
  
  .plc-details h2 {
    margin-top: 1rem;
  }
}

.instruction-copy-button .copied-text {
  margin-left: 10px;
  color: black;
}

.mobile-only {
  display: none;
}

@media (max-width: 767px) {
  .mobile-only {
    display: inline-flex;
  }
}

.plc-details ul {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}