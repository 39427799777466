.footer {
  background-color: #3F61DB;
  padding: 1rem 0;
  padding-left: 12rem;
  padding-right:12rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
}

.container2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.logoFooter {
  display: flex;
  align-items: center;
}

.logoFooter img {
  width: 50%; /* Make the logo smaller */
  height: auto;
}

.footer-nav {
  display: flex;
  align-items: center;
}

.footer-nav ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer-nav li {
  margin-left: 1.5rem;
}

.footer-nav a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-nav a:hover,
.footer-nav a.active {
  color: #f0a500;
}

.footer p {
  color: #fff;
  font-size: 1rem;
  margin: 0;
}

@media (max-width: 768px) {
  .logoFooter {
    display: none; /* Hide logo on small screens */
  }

  .footer-nav {
    display: none;
  }
}

@media (max-width: 992px) {
  .logoFooter {
    display: none; /* Hide logo on small screens */
  }
  .footer {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

@media (max-width: 768px) {
  .container2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .footer {
    padding-left: 2rem;
    padding-right:2rem;
  }
}

